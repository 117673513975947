import React from "react";
import { Image, Button } from "react-bootstrap";

import aboutUsImg from '../assets/image2.jpeg'
import missionImg from '../assets/image1.jpeg'
import opio from '../assets/opio-denis.jpg'
import hariet from '../assets/harriet.jpg'
import luke from '../assets/luke-ofungi.jpg'
import alanyo from '../assets/immaculate-alanyo.jpeg'
import aciro from '../assets/aciro-fiona.jpeg'
import stuart from '../assets/stuart-pickles.jpeg'
import edna from '../assets/ayero-edna.jpg'
import women from '../assets/awci-women.jpeg'
function AboutUs() {

    return (
        <>
            <div className="aboutus-cover">
                <div className="aboutus-header">
                    <h1 className="aboutus-title">About Anyim Wa Community Initiative</h1>
                    <div className="row text-center" id="subheading">
                        <div class="col-md-3 section">
                            <h4>
                                <a href="#our-story">Our Story</a>
                            </h4>
                        </div>
                        {/* <div class="col-md-3 section" id="mission">
                            <h4>
                                <a href="#vision">Our Vision</a>
                            </h4>
                        </div> */}
                        <div class="col-md-3 section" id="mission">
                            <h4>
                                <a href="#mission">Our Mission</a>
                            </h4>
                        </div>
                        <div class="col-md-4 section">
                            <h4>
                                <a href="#our-team">Meet The Team</a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sections */}
            <main>
                <section id="our-story" class="sections">
                    <div>
                        <div className='whyus-container'>
                            <div className='whyus-content'>
                                <div>
                                    <h1 className='whyus-title'>Our Story</h1>
                                    <p className='aboutus-text'>
                                        Anyim Wa community initiative is a woman and youth-led, coaching mentoring and empowerment non-profit organization with a registration in Northern Uganda, dedicated to offering a blend of creative and innovative empowerment opportunities to women and youth to transform lives for a better future. Our team of experienced professionals is passionate about providing personalized mentorship and coaching, ensuring that each individual receives tailored experience and skills to their unique needs and desires

                                        At Anyim Wa Community Initiative, we believe in the power of combining advanced strategies and approaches with a serene spa environment to rejuvenate both culture and mindset. We are committed to helping women and youth achieve their aspirations, ensuring they leave our programs inspired, motivated, and feeling revitalized and confident to transform their lives and of the community around them.
                                    </p>
                                </div>

                            </div>
                            <Image className='ourStoryImg ' src={aboutUsImg} alt="whyus img" />
                        </div>
                    </div>
                </section>

                <section id="vision" class="sections">
                    <div>
                        <div className='whyus-container'>
                            <Image className='ourStoryImg ' src={women} alt="lives change img" />
                            <div className='whyus-content'>
                                <div>
                                    <h1 className='whyus-title'>Our Vision</h1>
                                    <p className='aboutus-text'>
                                        Our Vision is to see a community where women and youth can translate their inner potential and capabilities to transform their lives and communities into better places of living
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="mission" class="sections">
                    <div>
                        <div className='whyus-container'>

                            <div className='whyus-content'>
                                <div>
                                    <h1 className='whyus-title'>Our Mission</h1>
                                    <p className='aboutus-text'>
                                        Our Mission is to unveil hidden and untapped inner potentials of women and youth through life skills empowerment, mentorship, and coaching so that they can get access to resources and opportunities, to live and develop with dignity for a better future and changed society.
                                    </p>
                                </div>
                            </div>
                            <Image className='ourStoryImg ' src={missionImg} alt="lives change img" />
                        </div>
                    </div>
                </section>

                <section id="our-team" class="sections">
                    <div>
                        <div className=''>

                            <h1 className='whyus-title text-center'>Meet The Team</h1>
                            <div className="row text-center team">
                                <div class="col-md-3 section">
                                    <Image className='teamImg ' src={opio} alt="opio img" />
                                    <h2 className="team-member">Denis Opio</h2>
                                    <h5 className="team-role">Founder & CEO</h5>
                                </div>
                                <div class="col-md-3 section" id="mission">
                                    <Image className='teamImg ' src={hariet} alt="hariet img" />
                                    <h2 className="team-member">Atim Harriet</h2>
                                    <h5 className="team-role">Co-founder & Secretary</h5>
                                </div>
                                <div class="col-md-3 section">
                                    <Image className='teamImg ' src={luke} alt="luke img" />
                                    <h2 className="team-member">Luke Ofungi</h2>
                                    <h5 className="team-role">Chairman Board</h5>
                                </div>
                                <div class="col-md-3 section">
                                    <Image className='teamImg ' src={alanyo} alt="luke img" />
                                    <h2 className="team-member">Immaculate Alanyo</h2>
                                    <h5 className="team-role">Board Advisor on Programs</h5>
                                </div>
                            </div>
                            <div className="row  text-center team">
                                <div class="col-md-3 section" id="mission">
                                    <Image className='teamImg ' src={edna} alt="edna img" />
                                    <h2 className="team-member">Ayero Edna</h2>
                                    <h5 className="team-role">Board Advisor on Finance</h5>
                                </div>
                                <div class="col-md-3 section">
                                    <Image className='teamImg ' src={stuart} alt="stuart img" />
                                    <h2 className="team-member">Stuart Pickles</h2>
                                    <h5 className="team-role">Board Advisor on Strategy</h5>
                                </div>
                                <div class="col-md-3 section">
                                    <Image className='teamImg ' src={aciro} alt="aciro img" />
                                    <h2 className="team-member">Aciro Fiona</h2>
                                    <h5 className="team-role">Board Advisor on Mentorship</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default AboutUs;