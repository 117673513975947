import React from 'react'
import { Image, Container, Row, Col } from 'react-bootstrap'


import Seedling from '../assets/programs/seedling.png'
import Donate from './shared/Donate'

function DonateView() {
    return (
        <Container>
            <div className="seedling-container mt-5">
                <div>
                    <div className="text-center">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>

                    <h1 className="text-center pt-2 progam-title">Support Anyim Wa Community Initiative</h1>

                </div>
            </div>
            <div className='pt-5'>
                <Donate />
            </div>
        </Container>
    )
}

export default DonateView