import React from 'react'
import { Image, Container, Row, Col, Form } from 'react-bootstrap'

import '../../Custom.css'

import Donate from '../donate/Donate'

import sewingMachine from '../../assets/ms/sewing-machine.jpg'
import Seedling from '../../assets/programs/seedling.png'

function DonateSewingMachine() {
    return (
        <Container>
            <div className="seedling-container mt-5">
                <div>
                    <div className="text-center">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>

                    <h1 className="text-center pt-2 progam-title">Support Anyim Wa Community Initiative</h1>

                </div>
            </div>
            <div className='mt-5 donate-sm'>
                <h1 className='text-center pt-4 aboutus-title'>Donate Sewing Machine</h1>
                <div className='donate-sew-container'>
                    <Row>
                        <Col md={3}>
                            <div>
                                <Image className='sewImg text-center' src={sewingMachine} alt="whyus img" />
                            </div>
                        </Col>
                        <Col md={6}>
                            {/* <Form.Check /> */}
                            <div className='amount-highlight-container'>
                                <p className='donate-item-text'>You can donate one (1) Sewing Machine</p>
                                <label className="container">$165
                                    <input
                                        type="checkbox"
                                        
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>

                            <Donate />
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default DonateSewingMachine